import React, { useMemo, useState } from "react";
import NavbarSidebarWrapper from "../../../components/navbarSidebarWrapper";
import MatkaBetModal from "../../../components/Modal/MatkaBet/MatkaBetModal";
import { useToast } from "../../../components/ToastContext";
import BetListTableModal from "../../../components/Modal/MatkaBet/BetListTableModal";
import { generateCrossingData, generateHarupData, generateTableData, parseInputValue } from "./Util";
import actions from "../../../redux/actions";
import { useParams } from "react-router-dom";
import MatkaBetList from "../MatkaBetResult/MatkaBetList";
import { useSelector } from "react-redux";

const MatkaBet = () => {
  const { showToast } = useToast();
  const { EventName, EventId, Mid } = useParams();

  const [betNumber, setBetNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [betListShowModal, setBetListShowModal] = useState(false);
  const [crossingShowModal, setCrossingShowModal] = useState(false);
  const [harupShowModal, setHarupShowModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [amountFromCrossingInput, setAmountFromCrossingInput] = useState("0");
  const [amountFromHarupInput, setAmountFromHarupInput] = useState("0");
  const [parsedData, setParsedData] = useState([]);
  const [crossingGenData, setCrossingGenData] = useState([]);
  const [harupGenData, setHarupGenData] = useState([]);
  const [selectedCrossing, setSelectedCrossing] = useState({ A: [], B: [] });
  const [selectedHarup, setSelectedHarup] = useState({ A: [], B: [] });

  const myBetList = useSelector((state) => state.smBetSlice.smMyBetList?.Result) || [];

  const crossingData = generateCrossingData();
  const harupData = generateHarupData();



  const handleEnterClick = () => {
    const parsed = parseInputValue(inputValue);

    if (!parsed) {
      showToast("Invalid input format! Expected format: 45-46-50@100", "error");
      return;
    }

    setParsedData(parsed);
    setBetListShowModal(true);
  };

  const handleNumberClick = (number) => {
    setBetNumber(number);
    setShowModal(true);
  };

  const handleBetSubmitAction = (betAmount) => {
    console.log(`Action handled with number: ${betNumber}, amount: ${betAmount}`);
    const SMBets = [
      {
        "BetNo": betNumber,
        "Amount": betAmount
      }
    ]
    handlerBetAction(SMBets, betAmount)
  };

  const handleMultipleBetAction = (totalBet, totalAmount, betType) => {
    if (betType) {
      const parsed = parseInputValue(inputValue);
      if (!parsed) {
        showToast("Invalid input format! Please correct it first.", "error");
        return;
      }
    }
    handlerBetAction(totalBet, totalAmount)
  };

  const handleCrossingSelect = (row, value) => {
    setSelectedCrossing((prev) => {
      const isSelected = prev[row].includes(value);
      return {
        ...prev,
        [row]: isSelected ? prev[row].filter((item) => item !== value) : [...prev[row], value],
      };
    });
  };

  const handleHarupSelect = (row, value) => {
    setSelectedHarup((prev) => {
      const isSelected = prev[row].includes(value);
      return {
        ...prev,
        [row]: isSelected ? prev[row].filter((item) => item !== value) : [...prev[row], value],
      };
    });
  };

  const generateCrossingSelectedNumbers = () => {
    const { A, B } = selectedCrossing;

    if (A.length === 0 || B.length === 0) {
      showToast("Please select at least one number from both rows (A and B).", "error");
      return;
    }

    const generatedNumbers = [];
    A.forEach((a) => {
      B.forEach((b) => {
        generatedNumbers.push(`${a}${b}`);
      });
    });
    const numbersWithAmount = generatedNumbers.map((num) => ({
      BetNo: num,
      Amount: amountFromCrossingInput
      // amount: (amountFromCrossingInput / generatedNumbers.length).toFixed(2)
    }));
    setCrossingGenData(numbersWithAmount);
    setCrossingShowModal(true);
  };

  const generateHarupSelectedNumbers = () => {
    const { A, B } = selectedHarup;
    if (A.length === 0 && B.length === 0) {
      showToast("Please select at least one number from both rows (A and B).", "error");
      return;
    }

    let generatedNumbers = [];
    A.forEach(value => {
      if (value.length === 4) {
        const firstDigit = value.charAt(0);
        for (let i = 0; i <= 9; i++) {
          generatedNumbers.push(`${firstDigit}${i}`);
        }
      }
    });

    B.forEach(value => {
      if (value.length === 3) {
        const secondDigit = value.charAt(1);
        for (let i = 0; i <= 9; i++) {
          generatedNumbers.push(`${i}${secondDigit}`);
        }
      }
    });

    // Remove duplicates by converting the array to a Set and then back to an array
    // generatedNumbers = [...new Set(generatedNumbers)];
    const numbersWithAmount = generatedNumbers.map((num) => ({
      BetNo: num,
      Amount: (amountFromHarupInput / 10).toFixed(2)

    }));
    setHarupGenData(numbersWithAmount);
    showToast(`Generated Numbers: ${generatedNumbers.join(", ")}`, "success");
    setHarupShowModal(true);
  };

  const handlerBetAction = async (totalBet, totalAmount) => {
    const data = {
      SportsId: 6,
      EventName,
      EventId,
      TotalAmount: parseInt(totalAmount),
      SMBets: totalBet,
    };

    try {
      const response = await actions.postBet(data);

      if (response && response.status === 200) {
        showToast("Bet submitted successfully", "success");
        return true;
      } else {
        showToast(response?.message || "Failed to submit the bet. Please try again.", "error");
        return false;
      }
    } catch (error) {
      showToast("An error occurred while submitting the bet. Please try again.", "error");
      return false;
    } finally {
      setBetNumber(0);
      setShowModal(false);
      setBetListShowModal(false);
      setCrossingShowModal(false);
      setHarupShowModal(false);
      setInputValue("");
      setAmountFromCrossingInput("0");
      setAmountFromHarupInput("0");
      setParsedData([]);
      setCrossingGenData([]);
      setHarupGenData([]);
      setSelectedCrossing({ A: [], B: [] });
      setSelectedHarup({ A: [], B: [] });
      actions.getSMMyBets(Mid);

    }
  };

  const tableData = generateTableData();

  const memoizedTableData = useMemo(() => {
    return tableData.map((row) => {
      return row.map((cell) => {
        // Retrieve bet amount (if applicable)
        const betAmount = myBetList
          .filter((item) => item.betNo.padStart(2, '0') === cell.padStart(2, '0')) // Find all matching betNo
          .reduce((acc, item) => acc + (Number(item.Amount) || 0), 0); // Convert Amount to a number before summing
        return {
          cell,
          betAmount,
        };
      });
    });
  }, [tableData, myBetList]);

  return (
    <NavbarSidebarWrapper>
      <div className="content-wrapper me-0">
        <div className="content-header d-flex justify-content-between">
          <span>{EventName} Market</span>
        </div>
        <div className="d-flex flex-wrap mt-4">
          <div className="col-12 col-md-8 scrollable-content">
            {/* <div className="scrollable-content-inner"> */}
            {/* Number Table */}
            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody>
                  {memoizedTableData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map(({ cell, betAmount }, cellIndex) => (
                        <td
                          key={cellIndex}
                          style={{
                            padding: "10px 0px 10px",
                            textAlign: "center",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          className={(rowIndex + cellIndex) % 2 === 0 ? "back" : "lay"}
                          onClick={() => handleNumberClick(cell)}
                        >
                          {cell} <br />
                          <small>{betAmount}</small>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>


            {/* PASTE HERE Section */}
            <div
              className="paste-here-section d-flex align-items-center justify-content-between"
              style={{
                width: "100%",
                marginTop: "20px",
                padding: "10px",
                backgroundColor: "#f9f9f9",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            >
              <span style={{ fontWeight: "bold" }}>PASTE HERE</span>
              <input
                type="text"
                placeholder="Enter Value Upto 1-99 (eg.1-99-22@110)"
                className="input-field ms-2"
                style={{
                  flexGrow: 1,
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button className="btn btn-primary" onClick={handleEnterClick}>
                ENTER
              </button>
            </div>

            {/* CROSSING Table */}
            <div className="table-responsive mt-4">
              <table className="table table-bordered custom-table">
                <tbody>
                  <tr>
                    <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                      CROSSING
                    </td>
                    <td>A</td>
                    {crossingData.A.map((cell, idx) => (
                      <td
                        key={idx}
                        style={{
                          backgroundColor: selectedCrossing.A.includes(cell)
                            ? "#d1ecf1"
                            : "transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => handleCrossingSelect("A", cell)}
                      >
                        {cell}
                      </td>
                    ))}
                    <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <input
                        type="number"
                        placeholder="Amount"
                        className="input-field ms-2"
                        onChange={(e) => setAmountFromCrossingInput(e.target.value)}
                      // style={{
                      //   flexGrow: 1,
                      //   marginLeft: "10px",
                      //   marginRight: "10px",
                      // }}

                      />
                    </td>
                    <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <button className="btn btn-primary btn-sm" onClick={generateCrossingSelectedNumbers}>
                        ENTER
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>B</td>
                    {crossingData.B.map((cell, idx) => (
                      <td
                        key={idx}
                        style={{
                          backgroundColor: selectedCrossing.B.includes(cell)
                            ? "#d1ecf1"
                            : "transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => handleCrossingSelect("B", cell)}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>

            {/* HARUP Table */}
            <div className="table-responsive mt-4">
              <table className="table table-bordered custom-table">
                <tbody>
                  <tr>
                    <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                      HARUP
                    </td>
                    <td>A</td>
                    {harupData.A.map((cell, idx) => (
                      <td key={idx}
                        style={{
                          backgroundColor: selectedHarup.A.includes(cell)
                            ? "#d1ecf1"
                            : "transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => handleHarupSelect("A", cell)}
                      >{cell}</td>
                    ))}
                    <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <input
                        type="number"
                        placeholder="Amount"
                        className="input-field ms-2"
                        onChange={(e) => setAmountFromHarupInput(e.target.value)}
                      />
                    </td>
                    <td rowSpan="2" style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <button className="btn btn-primary btn-sm" onClick={generateHarupSelectedNumbers}>ENTER</button>
                    </td>
                  </tr>
                  <tr>
                    <td>B</td>
                    {harupData.B.map((cell, idx) => (
                      <td key={idx} style={{
                        backgroundColor: selectedHarup.B.includes(cell)
                          ? "#d1ecf1"
                          : "transparent",
                        cursor: "pointer",
                      }}
                        onClick={() => handleHarupSelect("B", cell)}
                      >{cell}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <MatkaBetList Mid={Mid} BetList={myBetList} />
          </div>
        </div>

        {/* Modals */}
        <MatkaBetModal
          betNumber={betNumber}
          onActionPress={handleBetSubmitAction}
          show={showModal}
          setShow={setShowModal}
        />
        <BetListTableModal
          setShow={setBetListShowModal}
          show={betListShowModal}
          betDataList={parsedData}
          onActionPress={handleMultipleBetAction}
          betType={true}
        />
        <BetListTableModal
          setShow={setCrossingShowModal}
          show={crossingShowModal}
          betDataList={crossingGenData}
          onActionPress={handleMultipleBetAction}
          betType={false}
        />
        <BetListTableModal
          setShow={setHarupShowModal}
          show={harupShowModal}
          betDataList={harupGenData}
          onActionPress={handleMultipleBetAction}
          betType={false}
        />
      </div>
    </NavbarSidebarWrapper>
  );
};

export default MatkaBet;
